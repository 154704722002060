import { App } from "../../app";
import { CustomEventListener } from "../customEventListener";
import { Page } from "../../pages/page";
import { Glitter } from "./glitter";

export class GlitterTrail{
    private app: App;
    private page: Page;
    private totalGlitters = 10;
    private glitters: Glitter[];

    constructor(app: App, page: Page){
        this.app = app;
        this.page = page;
        this.glitters = [];

        let mouseEvent = new CustomEventListener(document.getElementsByTagName('html')[0], 'mousemove', (e: any) => {
            this.mouseHandler(e);
        });
        this.page.addCustomOverruler(mouseEvent);
    }

    private createGlitters(){
        for(let i = 0; i < this.totalGlitters; i++){
            let glitter = new Glitter(i);
            this.app.addToContent(glitter.getElement());
            this.glitters.push(glitter);
        }
    }

    private mouseHandler(e: any){
        if(this.glitters.length == 0){
            this.createGlitters();
        }

        let pos = this.getMousePos(e);
        if(typeof pos.x !== 'undefined' && typeof pos.y !== 'undefined'){
            for(let i = 0; i < this.glitters.length; i++){
                this.glitters[i].update(pos.x, pos.y);
            }
        }
    }

    private getMousePos(e:any) {
        var eventDoc, doc, body;

        e = e || window.event; // IE-ism

        // If pageX/Y aren't available and clientX/Y are,
        // calculate pageX/Y - logic taken from jQuery.
        // (This is to support old IE)
        if (e.pageX == null && e.clientX != null) {
            eventDoc = (e.target && e.target.ownerDocument) || document;
            doc = eventDoc.documentElement;
            body = eventDoc.body;

            e.pageX = e.clientX +
                (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
                (doc && doc.clientLeft || body && body.clientLeft || 0);
            e.pageY = e.clientY +
                (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
                (doc && doc.clientTop  || body && body.clientTop  || 0 );
        }

        // Use event.pageX / event.pageY here
        return {
            x: e.pageX,
            y: e.pageY
        };
    }
}