import './css/style.scss';
import { BlueScreenPage } from './pages/blueScreenPage';
import { Page } from "./pages/page";
import { QRPage } from './pages/qrPage';
import { ResultPage } from "./pages/resultPage";
import { UnderConstructionPage } from "./pages/underConstructionPage";
import { OldSchoolPage } from './pages/oldSchoolPage';
import cookieImage from "./images/cookie.png";
// import { DownloadPage } from './pages/downloadPage';

/**
 * TODOs:
 * Mallware screen
 * Napster with downloading Metallica songs
 */

export class App{
    private pages : (Page | null)[];
    private body: HTMLBodyElement;
    private cookie: HTMLElement;
    private currentPageId: number;
    private contentElement: HTMLElement;

    constructor(){
        this.body = document.getElementsByTagName('body')[0];
        this.contentElement = document.createElement('main');
        this.body.appendChild(this.contentElement);

        this.pages = [];

        if(this.body.id == "qr"){
            let qrpage = new QRPage(this, 'qrPage');
            this.pages.push(qrpage);

            this.init();
            this.initCookie();
            return;
        }

        let ucpage = new UnderConstructionPage(this, 'underConstructionPage');
        this.pages.push(ucpage);

        let resultPage = new ResultPage(this, 'resultPage');
        this.pages.push(resultPage);

        let oldSchoolPage = new OldSchoolPage(this, 'oldSchoolPage');
        this.pages.push(oldSchoolPage);

        let errorPage = new BlueScreenPage(this, 'blueScreenPage');
        this.pages.push(errorPage);

        // let downloadPage = new DownloadPage(this, 'downloadPage');
        // this.pages.push(downloadPage);

        this.init();
    }

    private init(pageId: number = 0, dataForPage: {} = {}){
        this.contentElement.innerHTML = "";
        if(typeof this.pages[pageId] == 'undefined'){
            return;
        }

        this.currentPageId = pageId;

        // manually search for page on localhost
        if(this.isLocalHost()){
            let queryStr = this.getQueryParam('q');
            if(queryStr != null){
                let query = Number(queryStr);
                if(!isNaN(query)){
                    this.currentPageId = query.valueOf();
                }
            }
        }

        console.log(this.currentPageId);

        let page = this.pages[this.currentPageId];
        this.body.id = page?.getPageId() + 'Body';
        this.contentElement.id = page !== null? page.getPageId() : '';
        page?.init(dataForPage);

        this.initCookie();

        this.run();
    }

    private initCookie(){
        if(this.currentPageId > 0){
            return;
        }

        let currentCookie = document.querySelector('cookie');
        if(currentCookie !== null){
            return;
        }

        console.log('init cookie');
        this.cookie = document.createElement('cookie');
        this.cookie.style.backgroundImage = `url("${cookieImage}")`;
        this.cookie.addEventListener('click', () => this.clickCookie());

        let tooltip = document.createElement('span');
        tooltip.classList.add('tooltip');
        tooltip.innerText = "While using this site, you're consenting to the cookie gods."
        this.cookie.appendChild(tooltip);

        this.contentElement.appendChild(this.cookie);
    }

    private clickCookie(){
        if(this.cookie.classList.contains('active')){
            this.cookie.classList.remove('active');
        }else{
            this.cookie.classList.add('active');
        }
    }

    private run() : void {
        if(typeof this.pages[this.currentPageId] != 'undefined'){
            this.pages[this.currentPageId]?.run();
        }

        requestAnimationFrame(() => this.run())
    }

    public addToContent(element: HTMLElement){
        this.contentElement.appendChild(element);
    }

    public getContentElement(): HTMLElement{
        return this.contentElement;
    }

    public getQueryParam(index: string): string | null{
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get(index);
    }

    public nextPage(dataForPage: {} = {}){
        this.pages[this.currentPageId] = null;
        this.init(this.currentPageId + 1, dataForPage);
    }

    private isLocalHost(): Boolean{
        return Boolean(
            window.location.hostname === 'localhost' ||
            // [::1] is the IPv6 localhost address.
            window.location.hostname === '[::1]' ||
            // 127.0.0.1/8 is considered localhost for IPv4.
            window.location.hostname.match(
                /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
            )
        );
    }
}

new App();