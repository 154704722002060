import { Page } from "./page";
import { faker } from '@faker-js/faker';
import { CustomEventListener } from "../components/customEventListener";
import { GlitterTrail } from "../components/oldSchool/glitterTrail";
import { CustomTimeout } from "../components/customTimeout";

/**
 * TODO: responsive warning
 */

export class OldSchoolPage extends Page{
    private glitterTrail: GlitterTrail;
    private timeout: CustomTimeout;

    public init(dataForPage: {} = {}): void {
        super.init(dataForPage);
        this.glitterTrail = new GlitterTrail(this.app, this);
        this.createOldSchoolPage();

        this.timeout = new CustomTimeout(5000, () => this.createMessage());
        this.addCustomOverruler(this.timeout);
    }

    public run(): void {}

    private createOldSchoolPage(){
        // <table>
        let table = document.createElement('table');
        this.app.addToContent(table);

        // <tr id="header">
        let trHeader = this.createAndAppendElement('tr', table);

        // <td id="logo">
        let tdLogo = this.createAndAppendElement('td', trHeader);
        tdLogo.id = "logo";

        // <td id="header">
        let tdHeader = this.createAndAppendElement('td', trHeader);
        tdHeader.id = "header";
        tdHeader.innerHTML = '<h1>My <blink>Awesome</blink> Site!</h1>';

        // <tr>
        let trContent = this.createAndAppendElement('tr', table);

        // <td id="menu">
        let tdMenu = this.createAndAppendElement('td', trContent);
        tdMenu.id = "menu";

        // <td id="content">
        let tdContent = this.createAndAppendElement('td', trContent);
        tdContent.id = "content";

        // <img>
        let imgLogo = this.createAndAppendElement('img', tdLogo) as HTMLImageElement;
        imgLogo.src = 'https://web.archive.org/web/20090829081147/http://www.geocities.com/teenibeani/blobkisswave.gif';

        // <img>
        let imgLogo2 = this.createAndAppendElement('img', tdLogo) as HTMLImageElement;
        imgLogo2.src = 'https://web.archive.org/web/20090831041121/http://geocities.com/colectores2/pussywave.gif';

        // <ul>
        let ulMenu = this.createAndAppendElement('ul', tdMenu) as HTMLUListElement;
        this.createMenu(ulMenu);
        // <a>
        let linkEmail = this.createAndAppendElement('a', tdMenu) as HTMLLinkElement;
        linkEmail.href = "/";
        // <a><img>
        let imgEmail = this.createAndAppendElement('img', linkEmail) as HTMLImageElement;
        imgEmail.src = "https://web.archive.org/web/20010727125834/http://www.geocities.com:80/SouthBeach/Sands/9980/sendemail.GIF";

        // <img>
        let imgContent = this.createAndAppendElement('img', tdContent) as HTMLImageElement;
        imgContent.src = faker.image.cats(500, 300);

        // <p>
        for(let p = 0; p < 4; p++){
            let par = this.createAndAppendElement('p', tdContent);
            par.innerText = faker.lorem.paragraph();
        }

        let img = this.createAndAppendElement('img', tdContent) as HTMLImageElement;
        img.src = "https://web.archive.org/web/20041018010045/http://www.geocities.com:80/taylorlynn.geo/barkitty.gif";
    }

    private createMessage(){
        this.removeSpecificCustomOverruler(this.timeout);

        let alert = document.createElement('alert');
        this.getApp().addToContent(alert);

        // bar
        let alertBar = this.createAndAppendElement('div', alert);
        alertBar.classList.add('bar');

        // <i class="bi bi-dash-square-fill"></i>
        let minIcon = this.createAndAppendElement('i', alertBar);
        minIcon.classList.add('bi', 'bi-dash-square-fill');

        // content
        let alertContent = this.createAndAppendElement('div', alert);
        alertContent.classList.add('content');

        // warning
        let contentWarning = this.createAndAppendElement('div', alertContent);
        contentWarning.classList.add('warning');

        // <i class="bi bi-sign-stop-fill"></i>
        let stopIcon = this.createAndAppendElement('i', contentWarning);
        stopIcon.classList.add('bi', 'bi-sign-stop-fill', 'stop');

        contentWarning.innerHTML += "<p>Please insert \"Disk 2\" and then click OK. Or click Cancel to quit Setup.</p>";

        // button ok
        let buttonOk = this.createAndAppendElement('button', alertContent);
        buttonOk.innerText = "OK";
        buttonOk.id = "buttonOk";
        let buttonOKEvent = new CustomEventListener(buttonOk, 'click', () => this.nextPage());
        this.addCustomOverruler(buttonOKEvent);

        // button cancel
        let buttonCancel = this.createAndAppendElement('button', alertContent);
        buttonCancel.innerText = "Cancel";
        buttonCancel.id = "buttonCancel";
        let buttonCancelEvent = new CustomEventListener(buttonCancel, 'click', () => this.nextPage());
        this.addCustomOverruler(buttonCancelEvent);
    }

    private createAndAppendElement(tagName: string, parent: HTMLElement): HTMLElement{
        let element = document.createElement(tagName);
        parent.appendChild(element);
        return element;
    }

    private createMenu(ulMain: HTMLUListElement){
        let mainElements = 3;
        let subElementsMinimum = 3;
        let subElementsMaximum = 6;

        for(let m = 0; m < mainElements; m++){
            // <ul><li>
            let li = this.createAndAppendElement('li', ulMain);
            // <ul><li><a>
            let link = this.createAndAppendElement('a', li) as HTMLLinkElement;
            link.innerText = faker.lorem.word();

            // <ul><li><ul>
            let ul = this.createAndAppendElement('ul', li);
            for(let s = 0; s < (Math.random() * (subElementsMaximum - subElementsMinimum)) + subElementsMinimum; s++){
                // <ul><li><ul><li>
                let subLi = this.createAndAppendElement('li', ul);
                // <ul><li><ul><li><a>
                let subLink = this.createAndAppendElement('a', subLi) as HTMLLinkElement;
                subLink.innerText = faker.lorem.word();
            }
        }
    }
}