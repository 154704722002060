

export class CustomEventListener implements CustomInterface{
    private element: HTMLElement | null;
    private action: string;
    private handler: Function;

    constructor(element: HTMLElement | null, action: string, handler: Function){
        this.element = element;
        this.action = action;
        this.handler = handler;

        this.add();
    }

    public getElement(): HTMLElement | null { return this.element; }
    public getAction(): string{ return this.action; }
    public getWait(): number { return 0; }
    public getHandler(): Function{ return this.handler; }

    public add(){
        this.element?.addEventListener(this.action, this.handler as EventListenerOrEventListenerObject);
    }

    public remove(){
        this.element?.removeEventListener(this.action, this.handler as EventListenerOrEventListenerObject);
    }

    public isTheSame(other: CustomInterface): boolean{
        if(typeof this !== typeof other
        || this.element !== other.getElement()
        || this.action !== other.getAction()
        || this.handler !== other.getHandler()){
            return false;
        }
        return true;
    }
}