import { CustomTimeout } from "../components/customTimeout";
import { Page } from "./page";
import qrImage from "../images/qr.png";

export class QRPage extends Page{
    private timeout: CustomTimeout;
    
    public init(dataForPage: {} = {}): void {
        super.init(dataForPage);
        this.createQRPage();
        this.timeout = new CustomTimeout(5000, () => window.location.href = "/");
    }

    public run(): void {}

    private createQRPage(){
        let header = document.createElement('flex');
        header.classList.add('column');
        this.app.addToContent(header);

        let qrcode = document.createElement('div') as HTMLElement;
        qrcode.classList.add('coverPageImage');
        qrcode.style.backgroundImage = `url("${qrImage}")`;
        header.appendChild(qrcode);

        let h1 = document.createElement('h1') as HTMLElement;
        h1.innerText = "So you always scan random QR codes?";
        header.appendChild(h1);

        let p = document.createElement('p') as HTMLElement;
        p.innerText = "Don't panic! It's all going to be okay.";
        header.appendChild(p);
    }
}