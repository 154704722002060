import { App } from "../app";

/**
 * TODO:
 * Work panic button
 */

export class Page{
    protected app: App;
    protected pageId: string;
    protected dataForPage: {};
    protected customOverrulers: CustomInterface[];

    constructor(app: App, pageId: string){
        this.app = app;
        this.pageId = pageId;
        this.customOverrulers = [];
    }

    public init(dataForPage: {} = {}){
        this.dataForPage = dataForPage;
    }

    public run(){
        console.log('run not implemented');
    }

    public getPageId(): string{
        return this.pageId;
    }

    public getApp(): App{
        return this.app;
    }

    public addCustomOverruler(custom: CustomInterface){
        this.customOverrulers.push(custom);
    }

    public removeSpecificCustomOverruler(custom: CustomInterface){
        for(let i = 0; i < this.customOverrulers.length; i++){
            if(this.customOverrulers[i].isTheSame(custom)){
                custom.remove();
                this.customOverrulers.splice(i, 1, custom);
                break;
            }
        }
    }

    protected goFullscreenHandler() {
        let elem = document.documentElement;

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }

    protected isFullscreenNow(): boolean{
        if(document.fullscreenElement !== null) {
            return true;
        }
        return false;
    }

    public nextPage(dataForPage: {} = {}){
        for(let i = 0; i < this.customOverrulers.length; i++){
            this.customOverrulers[i].remove();
        }
        this.app.nextPage(dataForPage);
    }

    protected animateCSS(element: string, animation: string, utilityClasses: string[] = []): Promise<any>{
        let prefix = 'animate__';

        // We create a Promise and return it
        return new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            const node = document.querySelector(element);
            
            node?.classList.add(`${prefix}animated`, animationName);
            for(let utility of utilityClasses){
                node?.classList.add(prefix+utility);
            }

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event: Event) {
                event.stopPropagation();
                node?.classList.remove(`${prefix}animated`, animationName);
                for(let utility of utilityClasses){
                    node?.classList.remove(prefix+utility);
                }
                resolve('Animation ended');
            }

            node?.addEventListener('animationend', handleAnimationEnd, {once: true});
        });
    }
}