
export class Glitter{
    private nth: number; // number in row
    protected elem: HTMLElement;

    constructor(nth: number){
        this.nth = nth;
        this.elem = document.createElement('span');
        this.elem.classList.add('glitter');
        this.elem.innerText = ".";
    }

    public getElement(): HTMLSpanElement{
        return this.elem;
    }
    
    public update(x: number, y: number){
        x += this.nth * 2 + 4;
        y += this.nth * 5 + 8;
        this.elem.style.transition = `${this.nth * 20}ms`;
        this.elem.style.transform = "translate("+x+"px,"+y+"px)";
    }
  }