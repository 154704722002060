import { App } from "../app";
import { Page } from "./page";
import tapeImage from "../images/caution.png";

export class UnderConstructionPage extends Page{
    // elements
    private input: HTMLInputElement;
    private underConstruction: HTMLElement;
    private animationRunned = false;

    private readonly dropSpeed = 3000;
    private readonly dissolveSpeed = 3000;

    constructor(app: App, pageId: string){
        super(app, pageId);
    }

    public init(dataForPage: {} = {}){
        super.init(dataForPage);

        this.underConstruction = document.createElement('div') as HTMLElement;
        this.underConstruction.style.backgroundImage = `url("${tapeImage}")`;
        this.underConstruction.id = 'underConstruction';
        this.underConstruction.classList.add('coverPageImage');
        this.app.addToContent(this.underConstruction);

        this.input = document.createElement('input') as HTMLInputElement;
        this.input.id = 'inputUser';
        this.input.setAttribute('placeholder', 'type here...');

        this.app.addToContent(this.input);
    }

    public run(){
        if(this.input.value.length <= 2){
            return;
        }

        this.runAnimation();
    }

    protected runAnimation(){
        if(this.animationRunned){
            return;
        }
        this.animationRunned = true;

        this.animateCSS('#'+this.underConstruction.id, 'flash', ['faster', 'infinite']);

        this.animateCSS('#'+this.input.id, 'hinge', ['slower']).then((message) => {
            this.input.remove();
            this.animateCSS('body', 'fadeOut', ['slower']).then((message) => {
                this.next();
            });
        });
    }

    private next(){
        this.nextPage({'inputValue' : this.input.value});
    }
}