import { Page } from "./page";
import { faker } from '@faker-js/faker';
import { CustomEventListener } from "../components/customEventListener";
import { CustomTimeout } from "../components/customTimeout";
import tapeImage from "../images/caution.png";
import clippyImage from "../images/clippy.png";

/**
 * TODO: issue on mobile; clippy not clickable (outside of screen)
 */

export class ResultPage extends Page{
    private timeout: CustomTimeout;
    private resultHelpListener: CustomEventListener;
    private resultNoHelpListener: CustomEventListener;
    private clippyTextElement: HTMLElement;

    private readonly noHelpQoutes = [
        "It looks like you're wrong. Are you sure you don't want any help?",
        "Wait what?",
        "Please let me help!",
        "It looks like you are making a mistake. I'm helping you."
    ];

    public init(dataForPage: {} = {}): void {
        super.init(dataForPage);
        this.createResultPage();
        this.timeout = new CustomTimeout(5000, () => this.clippyHandler());
    }

    public run(): void {}

    private clippyHandler(){
        this.createClippy();
    }

    private createResultPage(){
        let value = "";
        if(this.dataForPage.hasOwnProperty('inputValue')){
            value = (this.dataForPage as any).inputValue as string;
        }

        let header = document.createElement('flex');
        this.app.addToContent(header);

        let underConstruction = document.createElement('div') as HTMLElement;
        underConstruction.style.backgroundImage = `url("${tapeImage}")`;
        // underConstruction.setAttribute('id', 'underConstruction');
        underConstruction.classList.add('coverPageImage');
        header.appendChild(underConstruction);

        let input = document.createElement('input') as HTMLInputElement;
        input.setAttribute('id', 'inputUser');
        input.value = value;
        header.appendChild(input);

        let counter = document.createElement('counter');
        counter.setAttribute('id', 'counter');
        counter.innerText = `About ${faker.datatype.number({ min: 1000000, max: 100000000 }) } results`;
        this.app.addToContent(counter);

        let resultsContainer = document.createElement('results');
        this.app.addToContent(resultsContainer);

        for(let i = 0; i < 6; i++){
            let resultContainer = document.createElement('result');
            resultsContainer.appendChild(resultContainer);

            let url = document.createElement('url');
            url.innerText = faker.internet.domainName();
            resultContainer.appendChild(url);

            let title = document.createElement('title');
            title.innerText = faker.hacker.phrase();
            resultContainer.appendChild(title);

            let description = document.createElement('desc');
            description.innerText = faker.lorem.paragraph();
            resultContainer.appendChild(description);
        }
    }

    private createClippy(){
        let clippy = document.createElement('clip');

        let speechContainer = document.createElement('blockquote');
        speechContainer.classList.add('speech-bubble');
        this.clippyTextElement = document.createElement('p');
        this.setClippyContent("It looks like you are lost. Do you want any help to continue?",
            this.createHelpButtons());
        speechContainer.appendChild(this.clippyTextElement);
        clippy.appendChild(speechContainer);

        let clipIcon = document.createElement('icon');
        clipIcon.style.backgroundImage = `url("${clippyImage}")`;
        clippy.appendChild(clipIcon);

        this.app.addToContent(clippy);

        this.addHelpButtonListeners();

        this.animateCSS('clip', 'slideInRight');
    }

    private createHelpButtons(): HTMLElement{
        let container = document.createElement('div');

        let helpButton = document.createElement('button');
        helpButton.id = "resultHelp";
        helpButton.innerText = "Yes";
        container.appendChild(helpButton);

        let noHelpButton = document.createElement('button');
        noHelpButton.id = "resultNoHelp";
        noHelpButton.innerText = "No";
        container.appendChild(noHelpButton);

        return container;
    }

    private addHelpButtonListeners(){
        this.resultHelpListener = new CustomEventListener(
            document.getElementById('resultHelp'),
            'click',
            (event: Event) => this.helpHandler(event as MouseEvent)
        );
        this.addCustomOverruler(this.resultHelpListener);
        this.resultNoHelpListener = new CustomEventListener(
            document.getElementById('resultNoHelp'),
            'click',
            (event: Event) => this.declineHandler(event as MouseEvent)
        );
        this.addCustomOverruler(this.resultNoHelpListener);
    }

    private removeHelpButtonListeners(){
        this.removeSpecificCustomOverruler(this.resultHelpListener);
        this.removeSpecificCustomOverruler(this.resultNoHelpListener);
    }

    private setClippyContent(text: string, buttonContainer : HTMLElement | null = null){
        this.clippyTextElement.innerHTML = "";
        this.clippyTextElement.innerHTML = text;
        if(typeof buttonContainer != null){
            this.clippyTextElement.appendChild(this.createHelpButtons());
        }
    }

    private helpHandler(event: MouseEvent){
        this.removeHelpButtonListeners();
        this.animateCSS('main', 'slideOutDown').then((message) => {
            this.nextPage();
        });
    }

    private declineHandler(event: MouseEvent){
        this.removeHelpButtonListeners();

        let randomQoute = this.noHelpQoutes[Math.floor(Math.random() * this.noHelpQoutes.length)];
        this.setClippyContent(randomQoute, this.createHelpButtons());

        this.addHelpButtonListeners();
    }
}