import { CustomEventListener } from "../components/customEventListener";
import { Page } from "./page";

export class BlueScreenPage extends Page{
    private escEvent: CustomEventListener;

    public init(dataForPage: {} = {}){
        super.init(dataForPage);
        this.dataForPage = dataForPage;

        document.getElementsByTagName('html')[0].id = "blueScreenPageHTML";

        let errorText = "<p>A problem has been detected and Silly UX Department has been shut down to prevent damage to your computer.</p>"
            +"<p>If this is the first time you've seen this stop error screen, restart your computer. If this screen appears again, follow these steps:</p>"
            +"<p>Check to make sure any new hardware or software is properly installed. If this is a new installation, ask your hardware or software manufacturer for and Silly UX Department updates you might need.</p>"
            +"<p>If problems continue, disable or remove any newly installed hardware or software. Disable BIOS memory options such as caching or shadowing. If you need to use Safe Mode to remove or disable components, press ESC to select Advanced Startup Options, and then select Safe Mode.</p>"
            +"<p>Technical information:</p>"
            +"<p>*** STOP: 0x000000FE (0x00000008, 0x000000006, 0x00000009, 0x847075cc)</p>";

        let content = document.createElement('span');
        content.innerHTML = errorText;

        this.escEvent = new CustomEventListener(document, 'fullscreenchange', (e: Event) => this.escHandler(e))
        this.addCustomOverruler(this.escEvent);

        this.app.addToContent(content);
        this.goFullscreenHandler();
    }

    public run(){}

    /**
     * TODO: gives permission check failed
     */
    private escHandler(e: Event){
        if(this.isFullscreenNow()) {
            return;
        }
        this.nextPage();
    }
}