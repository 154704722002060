

export class CustomTimeout implements CustomInterface{
    private wait: number;
    private handler: Function;
    private timeout: number;

    constructor(wait: number, handler: Function){
        this.wait = wait;
        this.handler = handler;
        
        this.add();
    }

    public getElement(): HTMLElement | null { return null; }
    public getAction(): string { return ""; }
    public getWait(): number { return this.wait; }
    public getHandler(): Function{ return this.handler; }

    public add(){
        this.timeout = setTimeout(this.handler, 5000);
    }

    public remove(){
        clearTimeout(this.timeout);
    }

    public isTheSame(other: CustomInterface): boolean{
        if(typeof this !== typeof other
        || this.wait !== other.getWait()
        || this.handler !== other.getHandler()){
            return false;
        }
        return true;
    }
}